import React from 'react';

function App() {
  return (
    <div className="container">
      <h1 className="text-2xl font-bold">Hello, React with Tailwind CSS!</h1>
      <p className="mt-4">This is a basic setup.</p>
    </div>
  );
}

export default App;
